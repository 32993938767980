import NavBar from './commonHooks/commonHooks.navBar'; 

const GetStarted = () => {
    return (
        <div>
            <NavBar />
        </div>
    )
}

export default GetStarted