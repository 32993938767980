import React from 'react'
import NavBar from './commonHooks/commonHooks.navBar'; 


const Settings = () => {
    return (
        <div>
            <NavBar />
        </div>
    )
}

export default Settings